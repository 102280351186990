import BaseFooter from "app/components/layout/BaseFooter";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "../../../css/authLayout/auth.css";

const AuthLayout = ({ children }) => {
  return (
    <div className="d-flex flex-column" id="h100">
      <div id="page-content" className="auth-content">
        <Container>
          <Card className="card-auth center-div shadow-box">
            <Row>
              <Col lg={6} className="d-none d-lg-block">
                <div className="card-body text-center">
                  <Image
                    rounded="true"
                    className="img-responsive"
                    src="https://menuclick24.s3.eu-west-1.amazonaws.com/static/Menuclick24-QRCode.png"
                    alt="MenuClick24 Subscription"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="card-body text-center">
                  <Image
                    className="logo m-b-40 img-responsive"
                    src="https://menuclick24.s3.eu-west-1.amazonaws.com/static/menuclick24-logo.svg"
                    alt="MenuClick24 Logo"
                  />
                  {children}
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
      <BaseFooter />
    </div>
  );
};

export default AuthLayout;
