export default {
  loginPage: {
    access: "Accedi",
    register: "Registrati",
    forgotPassword: "Password dimenticata?",
    didntReceivedActivationEmail: "Non hai ricevuto la mail di attivazione?",
    confirm: "Conferma",
    emailPasswordInvalid: "Email o password invalidi",
  },
  registerPage: {
    invalidSubdomain: "Formato sottodominio invalido",
    termsAccepted: "I termini e condizioni devono essere accettati.",
    confirmEmailSent:
      "Una email con il codice di attivazione è stata inviata al tuo indirizzo. Controlla la posta in arrivo.",
    emailPasswordInvalid: "Email o password non sono valide",
    subdomain: "Sottodominio",
    registerConsent:
      "Cliccando il bottone 'Registrati' acconsento ai termini e condizioni di utilizzo e accetto che le mie informazioni e dati saranno usati in conformità alla privacy policy.",
    terms: "Temini e condizioni",
    privacy: "Privacy e Policy",
    marketingConsent:
      "MenuClick24 si impegna a proteggere e rispettare la tua privacy e utilizzerà le tue informazioni personali solo per gestire il tuo account e fornire il servizio da te richiesto. Ogni tanto ci piacerebbe comunque tenerti aggiornato sui nostri prodotti e servizi ed altri contenuti che potrebbero interessarti.",
    alreadyRegistered: "Già registrato?",
    didntReceivedActivationEmail: "Non hai ricevuto la mail di attivazione?",
    confirm: "Conferma",
    register: "Registrati",
    name: "Nome",
    surname: "Cognome",
  },
  forgotPasswordPage: {
    passwordEmailSent:
      "Una email con il codice di reset password è stata inviata al tuo indirizzo. Controlla la posta in arrivo.",
    resetPasswordFailure:
      "Il ripristino della password non è andato a buon fine, sei pregato di riprovare",
    sendCode: "Invia il codice",
    back: "Torna alla pagina di login",
    passwordReset: "Ripristina password",
  },
  resendActivationPage: {
    emailSent:
      "Una email con il codice di attivazione è stata inviata al tuo indirizzo. Controlla la posta in arrivo.",
    invalidEmail: "Email non valida",
    resendActivationCode: "Invia il codice",
    back: "Torna alla pagina di login",
    confirm: "Conferma",
  },
  resetPasswordPage: {
    passwordUpdated: "La tua password è stata aggiornata con successo",
    resetPasswordFailure:
      "Il ripristino della password non è andato a buon fine, sei pregato di riprovare",
    changePassword: "Cambia password",
    updatePassword: "Aggiorna password",
    back: "Torna alla pagina di login",
  },
  activateAccountPage: {
    accountActivated: "Il tuo account è stato attivato",
    activationFailure:
      "L'attivazione non è andata a buon fine, sei pregato di riprovare",
    activateAccount: "Attiva account",
    activate: "Attiva",
    back: "Torna alla pagina di login",
  },
  accountForm: {
    companyName: "Nome azienda",
    phoneNumber: "Numero di telefono",
    billingAddress: "Indirizzo di fatturazione",
    vatNumber: "P.IVA",
    update: "Aggiorna dettagli di fatturazione",
    companyCountry: "Stato",
    selectOne: "Seleziona",
    pec: "PEC",
    sdi: "SDI",
    onlyForItalians: " solo per aziende italiane",
    companyCity: "Città",
    companyProvince: "Provincia",
  },
  addCardPage: {
    creditCard: "Dati carta di credito",
  },
  editAccountPage: {
    detailsUpdated: "I dettagli di fatturazione sono stati aggiornati",
    details: "Dettagli di fatturazione",
  },
  editUserPage: {
    passwordRequired: "La password è richiesta",
    passwordUpdated: "La tua password è stata aggiornata con successo",
    changePassword: "Cambia password",
    insertNewPassword: "Inserisci una nuova password",
    updatePassword: "Aggiorna password",
  },
  stripeCCForm: {
    cardAdded: "Carta di credito aggiunta con successo",
    cardOwner: "Titolare carta di credito",
    addCard: "Aggiungi questa carta",
    addCardFailed:
      "La carta di credito non è stata aggiunta a causa di questi errori:",
  },
  planCard: {
    updateSubscription: "Aggiorna l'abbonamento",
    areYouSure: "Sei sicuro di aggiornare il piano?",
    yes: "Si",
    no: "No",
    planUpdated: "Piano aggiornato con successo",
    month: "/mese",
    year: "/anno",
    changePlan: "Cambia piano",
    currentPlan: "Il tuo piano attuale",
    selectPlan: "Seleziona questo piano",
    toPay: "Da pagare",
    plusVat: "+ IVA",
  },
  planPage: {
    selectaPlan: "Seleziona un piano",
    monthly: "Piano mensile",
    yearly: "Piano annuale",
    deactivatedAccountNotice:
      "Il tuo account non è attivo. Sei pregato di sottoscrivere un abbonamento per attivarlo.",
  },
  stripeForm: {
    paymentFailed: "Il pagamento è fallito a causa di questi errori:",
    paymentCompleted: "Pagamento completato con successo",
    cardOwner: "Titolare carta di credito",
    subscribe: "Abbonati",
  },
  subscribePlanPage: {
    billingDetails: "Dettagli di fatturazione",
    yourOrder: "Il tuo ordine",
    plan: "Piano",
    invoicing: "Fatturazione",
    price: "Prezzo",
    monthly: "Piano mensile",
    yearly: "Piano annuale",
    creditCard: "Dati carta di credito",
    subscribe: "Abbonati",
  },
  trialComponent: {
    youAreOnTrial:
      "Stai attualmente utilizzando la versione di prova di MenuClick24.",
    trialEndsAt: "Il periodo di prova termina il",
    goToPlans: "Va ai piani",
  },
  dashboardPage: {
    unsubscribe: "Cancella abbonamento",
    areYouSureToUnsubscribe:
      "Sei sicuro di cancellare l'abbonamento? In ogni caso, l'abbonamento rimarrà attivo fino alla fine del periodo corrente.",
    yes: "Si",
    no: "No",
    removeCard: "Rimuovi la carta",
    areYouSureToRemoveCard: "Sei sicuro di rimuovere questa carta?",
    makeDefault: "Rendi principale",
    areYouSureMakeDefault: "Sei sicuro di rendere questa carta la principale?",
    manageSubscription:
      "In questo pannello puoi gestire l'abbonamento e controllare il tuo storico pagamenti.",
    failedPaymentAt: "ATTENZIONE! Hai un pagamento fallito il",
    checkYourPayments:
      "Prego controlla i tuoi pagamenti in sospeso nello storico pagamenti o nella tua casella email.",
    subscriptionDeactivateOn:
      "Il tuo abbonamento si disattiverà automaticamente il",
    subscriptionRenewOn: "Il tuo abbonamento si rinnoverà automaticamente il",
    yourSubscription: "Il tuo abbonamento",
    plan: "Piano",
    price: "Prezzo",
    canceledAt: "Cancellato il",
    willDeactivateAt: "Si disattiverà il",
    willRenewOn: "Si rinnoverà il",
    paymentFailedAt: "Pagamento fallito il",
    paymentMethods: "I tuoi metodi di pagamento",
    default: "principale",
    remove: "rimuovi",
    addCreditCard: "Aggiungi carta di credito",
    changePlan: "Cambia piano",
    deleteSubscription: "Cancella abbonamento",
    paymentHistory: "Storico pagamenti",
    paymentId: "ID pagamento",
    status: "Stato",
    date: "Data",
    total: "Totale",
    paid: "Pagato",
    toPay: "Da pagare",
    actions: "Azioni",
    plusVat: "+ IVA",
  },
  privateLayout: {
    billingDetails: "Dettagli fatturazione",
    editUser: "Modifica utente",
    logout: "Logout",
    toggle: "Attiva",
    users: "Gestisci utenti",
    manageMenu: "Gestisci il menu",
  },
  indexUsersPage: {
    users: "Utenti",
    deleteUser: "Cancella utente",
    areYouSureToDelete: "Sei sicuro di cancellare questo utente?",
    yes: "Si",
    no: "No",
    userCreated: "Utente creato",
    userUpdated: "Utente aggiornato",
    name: "Nome",
    surname: "Cognome",
    email: "Email",
    role: "Ruolo",
    actions: "Azioni",
    editUser: "Modifica utente",
    deleteUser: "Cancella utente",
    addUser: "Aggiungi utente",
  },
  createUsersPage: {
    createUser: "Crea un nuovo utente",
    userCreated: "Utente creato",
  },
  updateUsersPage: {
    updatedUser: "Aggiorna utente",
    userUpdated: "utente aggiornato",
  },
  usersForm: {
    name: "Nome",
    surname: "Cognome",
    email: "Email",
    language: "Lingua",
    role: "Ruolo",
    user: "Utente",
    admin: "Amministratore",
    save: "Salva",
  },
  userDashboardPage: {
    manageMenu: "Gestisci il menu",
  },
};
