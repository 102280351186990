import en from "./en";
import it from "./it";

export default {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
};
