import { CreateCustomerCheckoutSession, UpdateAccount } from "api/mutations";
import { Plans } from "api/queries";
import Box from "app/components/dashboard/Box";
import Loader from "app/components/Loader";
import AccountForm from "app/pages/user/AccountForm";
import { selectedPlanState } from "libs/atoms";
import { formatMoney } from "libs/utils";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Elements, StripeProvider } from "react-stripe-elements";
import { useRecoilValue } from "recoil";
import StripeForm from "./StripeForm";

const SubscribePlanPage = (props) => {
  const { t } = useTranslation();

  const planId = useRecoilValue(selectedPlanState);

  const [selectedPlan, setSelectedPlan] = useState(null);

  const mutation = useMutation(UpdateAccount);

  const [invoicingUpdated, setInvoicingUpdated] = useState(false);

  const customerCheckoutSessionMutate = useMutation(
    CreateCustomerCheckoutSession,
    {}
  );

  const redirectToCustomerCheckoutSessionMutate = async () => {
    const response = await customerCheckoutSessionMutate.mutateAsync({
      planId: selectedPlan.id,
    });
    window.location.href = response.data.redirect_url;
  };

  const onSubmit = async (data) => {
    try {
      await mutation.mutateAsync({
        accountId: props.user.accountId,
        data: data,
      });
      setInvoicingUpdated(true);
    } catch (error) {
      setInvoicingUpdated(false);
    }
  };

  const { isLoading: plansLoading, data: plansData } = useQuery(
    "Plans",
    Plans,
    {
      retry: false,
      onSuccess: (plansData) => {
        const sp = plansData.data.plans.filter((p) => p.id === planId)[0];
        setSelectedPlan(sp);
      },
    }
  );

  if (plansLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Box
            header={
              <div>
                <h1>{t("subscribePlanPage.billingDetails")}</h1>
              </div>
            }
            body={<AccountForm user={props.user} onSubmit={onSubmit} />}
          />
        </Col>
      </Row>
      {selectedPlan && (
        <Row>
          <Col xs={12} style={{ marginTop: "30px" }}>
            <Box
              header={
                <div>
                  <h1>{t("subscribePlanPage.yourOrder")}</h1>
                </div>
              }
              body={
                <div>
                  <div className="inline-data">
                    <strong>{t("subscribePlanPage.plan")}</strong>
                    <span className="right">{selectedPlan.title}</span>
                  </div>
                  <div className="inline-data">
                    <strong>{t("subscribePlanPage.invoicing")}</strong>
                    <span className="right">
                      {selectedPlan.monthly
                        ? t("subscribePlanPage.monthly")
                        : t("subscribePlanPage.yearly")}
                    </span>
                  </div>
                  <div className="inline-data">
                    <strong>{t("subscribePlanPage.price")}</strong>
                    <span className="right">
                      {formatMoney(
                        "it",
                        selectedPlan.currency,
                        selectedPlan.price
                      )}{" "}
                      {t("dashboardPage.plusVat")}
                    </span>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        {invoicingUpdated && (
          <>
            <Col xs={12} style={{ marginTop: "30px" }}>
              <Button
                className="custom-btn green w-100-perc"
                onClick={() => {
                  redirectToCustomerCheckoutSessionMutate();
                }}
              >
                {t("subscribePlanPage.subscribe")}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default SubscribePlanPage;
