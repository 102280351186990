import { GenerateSso } from "api/mutations";
import Box from "app/components/dashboard/Box";
import { EXTERNAL_SSO_URL } from "config";
import { isFreeTrial } from "libs/utils";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import TrialComponent from "./TrialComponent";

const DashboardPage = ({ user }) => {
  const { t } = useTranslation();

  const generateSso = useMutation(GenerateSso, {});

  const sso = async () => {
    const res = await generateSso.mutateAsync();
    const externalSsoUrl = `${EXTERNAL_SSO_URL.replace(
      "*",
      user.account.subdomain
    )}/${res.data.sso}`;
    window.open(externalSsoUrl, "_blank");
  };

  return (
    <div className="dashboard-page">
      {isFreeTrial(user.account) ? (
        <TrialComponent user={user} />
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <Box
                header={
                  <div>
                    <h1>Dashboard</h1>
                  </div>
                }
                body={
                  <div>
                    <Button
                      onClick={() => sso()}
                      type="submit"
                      className="custom-btn green w-100-perc"
                      data-wait="Please wait..."
                    >
                      {t("userDashboardPage.manageMenu")}
                    </Button>
                  </div>
                }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default DashboardPage;
